import React, { useState } from 'react';
import iconClosed from './assets/icone-fechar.png';
import iconSearch from './assets/icone-lupa.png';
import { SearchWrapper, IconSearch, IconClosed, ResultWrapper } from './search.styled.js';

const Search = () => {

    const [searchIsOpen, /* setIsOpen */] = useState(false);

    return <SearchWrapper>
        {
            searchIsOpen
                ? <IconSearch src={iconSearch} alt='Busca' />
                : <ResultWrapper>
                    Logo
                <IconSearch src={iconSearch} alt='Busca' />
                    <IconClosed src={iconClosed} alt='Fechar' />
                Resultado
            </ResultWrapper>
        }
    </SearchWrapper>
}

export default Search;